import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Navbar from "../custom/Navbar"

const SecondPage = () => (
  <Layout>
    <Seo title="Meet your new Front End Developer" />
    <Navbar companyName="Netflix" />
  </Layout>
)

export default SecondPage
